/* import __COLOCATED_TEMPLATE__ from './inline-payment-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import betalinger from 'boel/utils/betalinger';
import { debug } from '@ember/debug';
import DateUtils from 'boel/utils/DateUtils';

export default class AppInlinePaymentListComponent extends Component {
  @service router;
  @service store;
  @service currentUser;
  @service session;
  @service notifications;
  @service intl;

  @tracked refresh;
  @tracked Visningstype;
  @tracked payments = [];
  @tracked Is_Loading;
  @tracked insurance_cases;

  constructor() {
    super(...arguments);
    this.Visningstype = 2;
  }
  @action
  async Fetch_Payments() {
    this.refresh = new Date().getTime();
    try {
      if (this.args.patient) {
        const patient_id = this.args.patient.id;

        if (patient_id) {
          this.Is_Loading = true;
          this.store.unloadAll('payment');
          let results = await this.store.query('payment', {
            filter: "equals(patient.id,'" + patient_id + "')",
            include:
              'insurancecase,patient,outlet,appointment,appointment.patient,appointment.outlet,appointment.appointmentstate,appointment.action,appointment.room,appointment.problem,appointment.treatment,appointment.behandlingsstatus',
            sort: 'appointment.time',
          });
          
          results = results.slice();
          results = results.sort((a, b) => {
            return a.appointment.time > b.appointment.time;
          });

          let cases = await this.store.query('insurancecase', {
            filter: "equals(patient.id,'" + patient_id + "')",
            include: 'patient,company,problem',
            sort: 'start',
          });
          cases = cases.slice();
          cases = cases.sort((a, b) => {
            return a.start > b.start;
          });
          for (let i2 = 0; i2 < results.length; i2++) {
            results[i2].has_insurancecase = false;
          }
          if (cases && results) {
            let antal = 0;
            let forsikring;

            for (let i = 0; i < cases.length; i++) 
            {
              forsikring = cases[i];
              antal = 0;
              for (let i2 = 0; i2 < results.length; i2++) {
                if (results[i2].insurancecase) {
                  if (results[i2].insurancecase.id === forsikring.id) {
                    antal++;
                  }
                }
              }
              for (let i2 = 0; i2 < results.length; i2++) {
                if (antal >= forsikring.amount) {
                  break;
                }
                // Gratis betalinger tæller ikke som forsikret
                if (results[i2].amount !== 0) {
                  if (results[i2].insurancecase == null) {
                    let inside_interval = DateUtils.In_DateInterval(
                      results[i2].appointment.time,
                      forsikring.start,
                      forsikring.end,
                    );
                    if (
                      !results[i2].has_insurancecase &&
                      inside_interval &&
                      !results[i2].paid
                    ) {
                      results[i2].has_insurancecase = true;
                      results[i2].temp_insurancecase = forsikring;
                      antal = antal + 1;

                      if (!results[i2].manuel) {
                        if (forsikring.price === 0) {
                          if (results[i2].patientamount)
                          {
                            results[i2].amount = results[i2].patientamount;
                          }
                          else {
                            results[i2].amount = results[i2].amount;
                          }
                        } else if (forsikring.price > 0) {
                          results[i2].amount = forsikring.price;
                        }
                      }
                    }
                  }
                }
              }
            }
            results.forEach((payment) => {
              payment.background_class =
                betalinger.get_background_color(payment);
            });
           // results = results.slice();
            results.sort((a, b) => {
              return a.appointment.time < b.appointment.time;
            });
          }
          this.insurance_cases = cases;
          this.payments = results;
          this.Is_Loading = false;
        }
      }
    } catch (err) {
      this.Is_Loading = false;
      console.log("Fetch_Payments:", err);
      debug(err);
    }
  }
  @cached
  get total_count() {
    return this.payments.length;
  }
  @cached
  get paid_count() {
    return this.payments.filter((element) => {
      return element.paid;
    }).length;
  }
  @cached
  get unpaid_count() {
    return this.payments.filter((element) => {
      return !element.paid;
    }).length;
  }
  @cached
  get unpaid() {
    return this.payments.reduce(function (total, element) {
      let thousands_seps = '.';
      let decimal_sep = ',';

      let amount = 0;
      if (typeof element.amount == 'string') {
        let sanitizeValue = element.amount
          .replace(thousands_seps, '')
          .replace(decimal_sep, '.')
          .replace(/[^0-9.-]+/, '');
        amount = parseFloat(sanitizeValue);
      } else {
        amount = element.amount;
      }
      return total + (element.paid ? 0 : amount);
    }, 0);
  }
  @action
  async ToggleManuel(payment /*, newValue*/) {
    payment.manuel = !payment.manuel;
    await payment.save();
  }
  @action
  async UpdateAmount(payment) {
    await payment.save();
    await this.Fetch_Payments();
  }
  @action
  async togglePaid(payment) {
    payment.paid = !payment.paid;
    if (payment.paid) {
      payment.time = new Date();
      if (payment.has_insurancecase && payment.temp_insurancecase) {
        payment.insurancecase = payment.temp_insurancecase;
      }
    }
    payment.background_class = betalinger.get_background_color(payment);
    payment.appointment.paid = payment.paid;

    await payment.save();
    await payment.appointment.save();
   this.payments = [...this.payments];
  }
}
