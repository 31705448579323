import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { hash } from 'rsvp';
import ENV from 'boel/config/environment';
import { later, cancel } from '@ember/runloop';
import DateUtils from 'boel/utils/DateUtils';

export default class AuthKabinestyringRoute extends Route {

  @service store;
  @service currentUser;
  @service router;
  @service kabinestyringhub;

  queryParams = {
    isodate: {
      refreshModel: true,
    },
    refresh1: {
      refreshModel: true,
    },
  };

  timeout;
  interval = ENV.APP.Kabinestyring_update_interval;

  async setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    const outlet = await this.currentUser.CurrentActiveOutlet;
    const outletid = this.currentUser.CurrentActiveOutletId;

    controller.set('chosenOutlet', outlet);
    controller.set('outletId', outletid);
    controller.set('Start_isoDate', model.Start_isoDate);
    controller.set('ChosenDay', new Date(model.Start_isoDate));
  }
  @action
  willTransition(transition) {
    if (this.timeout) {
      cancel(this.timeout);
    }
    if (transition.to.name !== 'auth.kabinestyring.index') {
      this.kabinestyringhub.close();
    }
  }
  afterModel(/* model,transition */) {
    let that = this;

    this.timeout = later(
      {},
      function () {
        const Current_Route = that.router.currentRouteName;
        const Is_Valid =
          !Current_Route ||
          that.router.currentRouteName.indexOf('kabinestyring') > -1;

        if (Is_Valid) {
          that.router.refresh();
        }
      },
      that.interval,
    );
  }
  async model(params) {
   const outlet = this.currentUser.CurrentActiveOutletId;
    let isodate = params.isodate;
    
    let today = new Date();

    if (!isodate)
    {
      isodate = today.toISOString().substring(0, 10);
      this.isodate = isodate;
    }
    else
    { 
      today = new Date(isodate);
    }

    let Start_isoDate = isodate;
    
    today.setDate(today.getDate() + 1);
    var End_isoDate = today.toISOString().substring(0, 10);

    var Insurancecases = await this.store.findAll('insurancecase', {
      include: 'company,patient,problem',
    });
    let aftaler = await this.store.query('appointment', {
      filter:
        "and(greaterOrEqual(time,'" +
        Start_isoDate +
        "'),lessThan(time,'" +
        End_isoDate +
        "'),equals(outlet.id,'" +
        outlet +
        "'),equals(isroombooking,'false'))",
      include: 'room,patient,problem,outlet,appointmentstate,action',
      sort: 'time',
    });

    // Validering af situation
    for (let en_aftale of aftaler) {
      en_aftale.background = '';
      en_aftale.background_1 = '';
      en_aftale.background_2 = '';
      en_aftale.background_3 = '';

      if (en_aftale.room && en_aftale.committed && !en_aftale.finished && !en_aftale.done && en_aftale.outside)
      {
        var now = new Date();
        var Previous = DateUtils.AddMinutes(now, -7);
        if (Previous >= new Date(en_aftale.committed)) {
          en_aftale.background_2 = 'bg-danger';
        }
      }
      if (en_aftale.room && en_aftale.finished && !en_aftale.done && en_aftale.outside)
      {
        var now = new Date();
        var Previous = DateUtils.AddMinutes(now, -5);
        if (Previous >= new Date(en_aftale.finished)) {
          en_aftale.background_1 = 'bg-danger';
        }
      }
      // else if (en_aftale.room && en_aftale.finished && en_aftale.done && en_aftale.outside)
      // {
      //   var now = new Date();
      //   var Previous = DateUtils.AddMinutes(now, -5);
      //   if (Previous >= new Date(en_aftale.done)) {
      //     en_aftale.background_2 = 'bg-danger';
      //   }
      // }
      if (en_aftale?.action?.name == "Vendes" && en_aftale.outside) {
        var now = new Date();
        var Previous = DateUtils.AddMinutes(now, -5);
        if (Previous >= new Date(en_aftale.done)) {
          en_aftale.background_3 = 'bg-danger';
        }
      }
      if (en_aftale?.action?.name == "Vendt" && en_aftale.outside) {
        var now = new Date();
        var Previous = DateUtils.AddMinutes(now, -5);
        if (Previous >= new Date(en_aftale.done)) {
          en_aftale.background_3 = 'bg-danger';
        }
      }
    }

   let result = await hash({
      Start_isoDate: Start_isoDate,
      End_isoDate: End_isoDate,
      aftaler: aftaler,
      rooms: this.store.query('room', {
        filter:
          "and(equals(outlet.id,'" + outlet + "'),equals(bookable,'false'))",
        include: 'outlet',
        sort: 'name-short',
      }),
      actions: this.store.query('action', {
        sort: 'name',
      }),
      insurancecases: Insurancecases,
      Updated: new Date(),
    });
    return result;
  }
}
